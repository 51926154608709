<template>
  <b-card title="All Warranty Report">
    <b-modal
      id="promptDownload"
      ref="modal"
      v-model="promptDownload"
      title="Download"
      hide-footer
      @hidden="resetNoData"
    >
      <strong v-if="warrantyToDownload.length <= 0 && noData === false">Loading data...</strong>
      <strong v-if="warrantyToDownload.length <= 0 && noData === true">Data from selected month range is not found, please change the month range</strong>
      <strong v-if="disableDownloadData">Data from selected month range is more than 50k, please narrow down the month range</strong>
      <strong v-if="warrantyToDownload.length != 0">Loading data finished, total data is {{ warrantyToDownload.length }}</strong>
      <div>
        <strong v-if="warrantyToDownload.length != 0 && !disableDownloadData"> Progress {{ progressDownload }} %</strong>
      </div>
      <div style="display: table; margin: 0 auto;">
        <b-button :disabled="warrantyToDownload.length <= 0 || disableDownloadData" variant="primary" style="margin-top: 20px">
          <download-excel :data="warrantyToDownload" :fields="active? filterNoReturnDate : filter" :name="'All Warranty Report.xls'" class="download-border">Download</download-excel>
        </b-button>
      </div>
    </b-modal>
    <b-col cols="12">
      <b-row>
        <b-col cols="6">
          <b-form-group label="From Month">
            <date-picker 
              v-model="fromMonth" 
              type="month" 
              format="MMMM YYYY" 
              :disabled-date="disableFromDate"
              style="width: 100%" 
              @change="updateToDateRange">
            </date-picker>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="To Month">
            <date-picker 
              v-model="toMonth" 
              type="month" 
              format="MMMM YYYY"
              style="width: 100%"
              :disabled-date="disableToDate">
            </date-picker>
          </b-form-group>
        </b-col>
        <b-col cols="6"/>
        <b-col cols="6" class="d-flex justify-content-end">
          <b-button :disabled="fromMonth === null || toMonth === null" variant="primary" style="margin-top: 25px" @click="openDownloadModal">
            Download
          </b-button>
        </b-col>
      </b-row>
      <br>
    </b-col>
  </b-card>
</template>
  
  <script>
  import { mapActions, mapState, mapGetters } from 'vuex';
  import axios from '@/axios';
  import vSelect from 'vue-select';
  import {userAccess,dateFormat} from '@/utils/utils'
  import DatePicker from "vue2-datepicker";
  import "vue2-datepicker/index.css";
  
  export default {
    components: {
      "date-picker": DatePicker,
      'v-select': vSelect,
    },
    name: 'barchart',
    props: {
      msg: String
    },
    data() {
      return {
        filter: {
          "Registration Date": {
            field: "createdAt",
            callback: (value) => {
              if (value) {
                return dateFormat(value);
              } else {
                return "Invalid Migration Data";
              }
            },
          },
          Certificate: "certNo",
          Model: "itemModel",
          Barcode: "barcode",
          "Installation Date": {
            field: "installationDate",
            callback: (value) => {
              if (value) {
                return dateFormat(value);
              } else {
                return "";
              }
            },
          },
          "Invoice Date": {
            field: "invoiceDate",
            callback: (value) => {
              if (value) {
                return dateFormat(value);
              } else {
                return "";
              }
            },
          },
          "Distribution Channel": "industryType",
          // 'Dealer Code'       : 'dealerCode',
          "Dealer Code": {
            field: "dealerCode",
            callback: (value) => ` ​${value}`,
          },
          "Dealer Name": "dealerName",
          Customer: "fullName",
          Email: "email",
          Serial: "serial",
          "DO No.": "sapDo",
          "Installation Address": "address",
          "Building Type": "buildingType",
          "Contractor Name": "installerName",
          Pcode: "pcode",
          "Product Hierarchy": {
            field: "phlvl1",
            callback: (value) => ` ​${value}`,
          },
          "Project Name": "name",
          "Top Date": {
            field: "topDate",
            callback: (value) => {
              if (value) {
                return dateFormat(value);
              } else {
                return "";
              }
            },
          },
          "Developer Name": "developer",
          "Warranty Status": "warrantyStatus",
          "flag Expiry": "flagExpiry",
          "General Warranty Expiry": {
            field: "generalWarrantyEndDate",
            callback: (value) => {
              if (value) {
                return dateFormat(value);
              } else {
                return "";
              }
            },
          },
          "Residential Warranty End Date": {
            field: "residentialWarrantyEndDate",
            callback: (value) => {
              if (value) {
                return dateFormat(value);
              } else {
                return "";
              }
            },
          },
          "Compressor Warranty Expiry": {
            field: "compressorWarrantyEndDate",
            callback: (value) => {
              let date = value ? dateFormat(value) : "";
              return date;
            },
          },
          "General Additional Period (Month)": "generalWarrantyDuration",
          "Compressor Additional Period (Month)": "compressorWarrantyDuration",
        },
        filterNoReturnDate: {
          'Invoice Number' : {
            field: 'invoice',
            callback: (value) => {
              return "'"+value
            }
          },
          'Customer Name' : 'customerName',
          'Customer Code' : {
            field: 'customerCode',
            callback: (value) => {
              return "'"+value
            }
          },
          'DO Number' : {
            field: 'DOnumber',
            callback: (value) => {
              return "'"+value
            }
          },
          'DO Date': 'Dodate',
          'Material':'material',
          'Quantity' : {
            field: 'quantity',
            callback: (value) => {
              return "'"+value
            }
          },
          'SBU' :{
            field: 'phier',
            callback: (value) => {
              return "'"+value
            }
          },
          'Return Reason' :'returnReason',
          'Return Status' :'returnStatus',
          'Return Number' :'flag',
        },
        metadata: { totalPages: 0, totalData: 0, lastRegisterDate: null },
        downPage:0,
        dataDownload:0,
        warrantyToDownload:[],
        promptDownload: false,
        dataToDownload:[],
        dataForTable:[],
        isLoading: false,
        productHierarchy: [],
        type : "",
        typeToggle:true,
        fromMonth: null,
        toMonth: null,
        active: false,
        totalRowsDownload: null,
        noData: false,
        disableDownloadData: false
      };
    },
    methods: {
      ...mapActions({
        getAllWarranty: "warranty/getAllWarranty",
      }),
      // Method For Download Report All Warranty
      async openDownloadModal() {
        this.downPage = 0;
        this.dataDownload = 0;
        this.warrantyToDownload = [];
        this.promptDownload = true;
        let page = this.downPage;
        let dataDownload = this.dataDownload;
        let startMonth = this.fromMonth.getMonth()
        let startYear = this.fromMonth.getFullYear()
        let endMonth = this.toMonth.getMonth()
        let endYear = this.toMonth.getFullYear()
        await this.getAllWarranty({
          startMonth, 
          startYear,
          endMonth,
          endYear,
          page: 1,
          entry: 400,
        })
        .then((res) => {
          // console.log('data fetch',data);
          console.log("res", res);
          
          this.processMetadata(res.metadata);
          if(this.totalData === 0 || res.data.length === 0){
            console.log("masuk sini");
            
            this.noData = true
            return
          }
        })
        .catch((err) => {
          console.log({ err });
        });
        if (!this.noData && !this.disableDownloadData) {
          while (dataDownload < this.metadata.totalData) {
            
            let dataProcess = [];
            let finalData = [];
            page++;
            this.downPage = page;
            await this.getAllWarranty({
              startMonth, 
              startYear,
              endMonth,
              endYear,
              page: page,
              entry: 400,
            }).then((res) => {
              dataProcess = res.data.data ? res.data.data: res.data
              // split into each item
              for (let i = 0; i < dataProcess.length; i++) {
                const element = dataProcess[i];
                if (element == null) {
                  console.log("ada null");
                  let address = "";
                  let address2 = "";
                  let address3 = "";
                  let buildingName = "";
                  let buildingType = "";
                  // console.log(`element ke ${i}`,dataProcess[i])
                  finalData.push({
                    certNo: "",
                    fullName: "",
                    salutation: "",
                    ref: "",
  
                    address: `${address} ${address2} ${address3} ${buildingName}`,
                    buildingType: buildingType,
                    customerType: "",
                    email: "",
                    phone: "",
                    postal: "",
                    registerType: "",
                    installerName: "",
                    installationDate: "",
                    warrantyStatus: "",
                    flagExpiry: "",
                    purchaseDate: "",
                    companyName: "",
  
                    sapDo: "",
                    dateDO: "",
                    dealerCode: "",
                    dealerName: "",
  
                    pcode: "",
                    phlvl1: "",
                    generalWarrantyDuration: "",
                    compressorWarrantyDuration: "",
                    residentialWarrantyEndDate: "",
                    generalWarrantyEndDate: "",
                    compressorWarrantyEndDate: "",
                    invoiceDate: "",
                    industryType: "",
  
                    barcode: "",
                    name: "",
                    topDate: "",
                    itemModel: "",
                    developer: "",
                    serial: "",
                    remarks: "",
                    createdAt: "",
                  });
                  // console.log({finalData});
                  continue;
                }
                let address = element.address ? element.address : "";
                let address2 = element.address2 ? element.address2 : "";
                let address3 = element.address3 ? element.address3 : "";
                let buildingName = element.buildingName ? element.buildingName : "";
                let buildingType = element.buildingType ? element.buildingType : "";
  
                finalData.push({
                  id: element.id,
                  certNo: element.certNo,
                  fullName: element.fullName,
                  salutation: element.salutation,
  
                  address: `${address} ${address2} ${address3} ${buildingName}`,
                  buildingType: buildingType,
                  customerType: element.customerType,
                  email: element.email,
                  phone: element.phone,
                  postal: element.postal,
                  postalCode: element.postalCode,
                  registerType: element.registerType,
                  installerName: element.installerName,
                  installationDate: element.installationDate,
                  warrantyStatus: element.warrantyStatus,
                  flagExpiry: element.flagExpiry,
                  purchaseDate: element.purchaseDate,
                  companyName: element.companyName,
  
                  sapDo: element.sapDo,
                  dateDO: element.dateDO,
                  dealerCode: element.dealerCode,
                  dealerName: element.dealerName,
                  phlvl1: element.phlvl1,
                  pcode: element.pcode,
                  generalWarrantyDuration: element.generalWarrantyDuration,
                  compressorWarrantyDuration: element.compressorWarrantyDuration,
                  residentialWarrantyEndDate: element.residentialWarrantyEndDate,
                  generalWarrantyEndDate: element.generalWarrantyEndDate,
                  compressorWarrantyEndDate: element.compressorWarrantyEndDate,
                  invoiceDate: element.invoiceDate,
                  industryType: element.industryType,
                  ref: element.ref,
                  barcode: element.barcode,
                  name: element.name,
                  topDate: element.topDate,
                  itemModel: element.itemModel,
                  developer: element.developer,
                  serial: element.serial,
                  remarks: element.remarks,
                  createdAt: element.createdAt,
                });
              }
              this.warrantyToDownload.push(...finalData);
              dataDownload += dataProcess.length;
              this.dataDownload = dataDownload;
            });
          }
        }
      },
      processMetadata(metadata) {
        // console.log("metadata", metadata);
        this.totalData = metadata.totalData;
        this.metadata.totalPages = metadata.totalPages;
        this.metadata.totalData = metadata.totalData;
        this.metadata.lastRegisterDate = metadata.lastRegisterDate;
        if (this.totalData >= 50000) {
          this.disableDownloadData = true
        }else{
          this.disableDownloadData = false
        }
      },

      updateToDateRange() {
        if (this.toMonth) {
          const maxAllowedDate = new Date(this.fromMonth.getFullYear(), this.fromMonth.getMonth() + 12, 1);
          if (this.toMonth > maxAllowedDate) {
            this.toMonth = maxAllowedDate;
          }
        }
      },
      
      dates(date) {
        //console.info('date ',date)
        if (date==undefined||date==""){
          return ""
        }
        return dateFormat(date);
      },

      resetNoData() {
        this.noData = false;
      }
    },
  
    computed: {
      ...mapGetters({
        returnReport: 'report/return'
      }),
      disableFromDate() {
        const minYear = 2023; // Hanya bisa memilih dari tahun 2023 ke atas
        const today = new Date();
        const minMonth = today.setMonth(today.getMonth()); // Geser ke bulan depan
        return (date) => date.getFullYear() < minYear || date > minMonth;
      },
      disableToDate() {
        const today = new Date();
        const minMonth = today.setMonth(today.getMonth()); // Geser ke bulan depan
        if (!this.fromMonth) return (date) => date > minMonth;
        const minToDate = new Date(this.fromMonth.getFullYear(), this.fromMonth.getMonth(), 1);
        const maxToDate = new Date(this.fromMonth.getFullYear(), this.fromMonth.getMonth() + 12, 1);
        return (date) => date < minToDate || date > maxToDate || date > minMonth;
      },
      progressDownload() {
        let downloaded = this.warrantyToDownload.length;
        let dataToDownload = this.metadata.totalData;
        return Math.ceil((downloaded / dataToDownload) * 100);
      },
      showCheckbox() {
        if (!this.fromMonth || !this.toMonth) return false;

        const fromDate = new Date(this.fromMonth);
        const toDate = new Date(this.toMonth);
        const minDate = new Date("2023-01-01");
        const maxDate = new Date("2025-02-05");

        return fromDate >= minDate && toDate <= maxDate;
      },
    },
    watch: {
      selectMonthA(newVal) {
        this.tableMonthA = newVal;
      },
      selectMonthB(newVal) {
        this.tableMonthB = newVal;
      },
      toFilterProductYears(newVal) {
        this.tableExp = newVal;
      },
    },
    created() {
      document.title = 'All Warranty Report | RSP';
    },
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
  }
  
  .controls {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .dropdown {
    padding: 10px;
    font-size: 16px;
  }
  
  .chart-container {
    margin-top: 20px;
  }
  
  </style>